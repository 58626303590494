import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './SeriesManager.css';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

const SeriesManager = () => {
    const [series, setSeries] = useState([]);
    const [filteredSeries, setFilteredSeries] = useState([]);
    const [filter, setFilter] = useState(localStorage.getItem('filter') || 'open');
    const [seriesTypeFilter, setSeriesTypeFilter] = useState(localStorage.getItem('seriesTypeFilter') || 'all');
    const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
    const [sportFilter, setSportFilter] = useState(localStorage.getItem('sportFilter') || 'all');
    const [newSport, setNewSport] = useState('');
    const [newSeriesType, setNewSeriesType] = useState('');
    const [teamName, setTeamName] = useState('');
    const [otherType, setOtherType] = useState('');
    const [subType, setSubType] = useState('');
    const [showZeroEnCours, setShowZeroEnCours] = useState(localStorage.getItem('showZeroEnCours') === 'true');
    const [showNonZeroEnCours, setShowNonZeroEnCours] = useState(localStorage.getItem('showNonZeroEnCours') === 'true');
    const [bankroll, setBankroll] = useState(1000);
    const [maxPerSeries, setMaxPerSeries] = useState(100);

    useEffect(() => {
        fetchSeries();
    }, []);

    useEffect(() => {
        applyFilter();
    }, [series, filter, seriesTypeFilter, searchTerm, sportFilter, showZeroEnCours, showNonZeroEnCours]);

    const fetchSeries = async () => {
        try {
            const [seriesResponse, bankrollResponse] = await Promise.all([
                axios.get(`${API_URL}/api/series`),
                axios.get(`${API_URL}/api/bankroll`)
            ]);

            console.log('Series response:', seriesResponse.data);

            const seriesWithDetails = seriesResponse.data.map(series => {
                const currentExpenses = calculateCurrentExpenses(series.bets || []);
                const totalExpenses = calculateTotalExpenses(series.bets || []);
                const lastTeamPlayed = getLastTeamPlayed(series.bets || []);
                return {
                    ...series,
                    currentExpenses,
                    totalExpenses,
                    lastTeamPlayed
                };
            });

            setSeries(seriesWithDetails);
            setBankroll(bankrollResponse.data.amount);
        } catch (error) {
            console.error('Error fetching series or bankroll:', error);
            alert('Error fetching series or bankroll: ' + error.message);
        }
    };

    const calculateCurrentExpenses = (bets) => {
        return bets.reduce((acc, bet) => {
            return bet.result ? acc : acc + parseFloat(bet.stake);
        }, 0).toFixed(2);
    };

    const calculateTotalExpenses = (bets) => {
        return bets.reduce((acc, bet) => acc + parseFloat(bet.stake), 0).toFixed(2);
    };

    const getLastTeamPlayed = (bets) => {
        if (!bets.length) return 'N/A';
        const lastBet = bets.reduce((latest, bet) => {
            return new Date(bet.date) > new Date(latest.date) ? bet : latest;
        }, bets[0]);
        return lastBet.team_name;
    };

    const sortSeries = (series) => {
        return series.sort((a, b) => {
            // Trier par type de pari
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;

            // Si les types sont égaux, trier par dépenses totales décroissantes
            return parseFloat(b.totalExpenses) - parseFloat(a.totalExpenses);
        });
    };

    const applyFilter = () => {
        let filtered = series;

        if (filter === 'open') {
            filtered = filtered.filter(s => !s.is_closed);
        } else if (filter === 'closed') {
            filtered = filtered.filter(s => s.is_closed);
        }

        if (seriesTypeFilter !== 'all') {
            filtered = filtered.filter(s => s.type === seriesTypeFilter);
        }

        if (sportFilter !== 'all') {
            filtered = filtered.filter(s => s.sport === sportFilter);
        }

        if (showZeroEnCours) {
            filtered = filtered.filter(s => parseFloat(s.currentExpenses) === 0);
        }

        if (showNonZeroEnCours) {
            filtered = filtered.filter(s => parseFloat(s.currentExpenses) !== 0);
        }

        if (searchTerm) {
            filtered = filtered.filter(s => s.name && s.name.toLowerCase().includes(searchTerm.toLowerCase()) || (s.bets && s.bets.some(bet => bet.team_name.toLowerCase().includes(searchTerm.toLowerCase()))));
        }

        // Appliquer le tri avant de définir les séries filtrées
        setFilteredSeries(sortSeries(filtered));
    };

    const addNewSeries = async () => {
        let name;
        let type;
        if (newSport === 'foot') {
            if (newSeriesType === 'équipe') {
                name = teamName;
                type = 'équipe';
            } else if (newSeriesType === 'autre') {
                name = otherType;
                type = subType ? `${otherType} - ${subType}` : otherType;
            }
        } else if (newSport === 'tennis') {
            name = otherType;
            type = subType ? `${otherType} - ${subType}` : otherType;
        }

        try {
            await axios.post(`${API_URL}/api/series`, { name, type, sport: newSport });
            setNewSport('');
            setNewSeriesType('');
            setTeamName('');
            setOtherType('');
            setSubType('');
            fetchSeries();
        } catch (error) {
            console.error('Error adding new series:', error);
            alert('Error adding new series: ' + error.message);
        }
    };

    const calculateRemainingSeries = () => {
        const totalExpenses = series.reduce((acc, s) => {
            return s.is_closed ? acc : acc + parseFloat(s.totalExpenses);
        }, 0);
        const remainingBankroll = bankroll - totalExpenses;
        return Math.floor(remainingBankroll / maxPerSeries);
    };

    const handleBankrollChange = (e) => {
        setBankroll(parseFloat(e.target.value));
    };

    const handleDeleteSeries = async (seriesId) => {
        try {
            await axios.delete(`${API_URL}/api/series/${seriesId}`);
            fetchSeries();
        } catch (error) {
            console.error('Failed to delete series:', error);
            alert('Failed to delete series.');
        }
    };

    const handleZeroEnCoursClick = () => {
        setShowZeroEnCours(true);
        setShowNonZeroEnCours(false);
        localStorage.setItem('showZeroEnCours', 'true');
        localStorage.setItem('showNonZeroEnCours', 'false');
    };

    const handleNonZeroEnCoursClick = () => {
        setShowZeroEnCours(false);
        setShowNonZeroEnCours(true);
        localStorage.setItem('showZeroEnCours', 'false');
        localStorage.setItem('showNonZeroEnCours', 'true');
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        localStorage.setItem('filter', e.target.value);
    };

    const handleSeriesTypeFilterChange = (e) => {
        setSeriesTypeFilter(e.target.value);
        localStorage.setItem('seriesTypeFilter', e.target.value);
    };

    const handleSportFilterChange = (e) => {
        setSportFilter(e.target.value);
        localStorage.setItem('sportFilter', e.target.value);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        localStorage.setItem('searchTerm', e.target.value);
    };

    const handleEligibleChange = async (seriesId, eligible) => {
        try {
            await axios.put(`${API_URL}/api/series/${seriesId}/eligibility`, { eligible });
            fetchSeries();  // Rafraîchir la liste après la mise à jour
        } catch (error) {
            console.error('Failed to update series eligibility:', error);
            alert('Failed to update series eligibility.');
        }
    };
    

    return (
        <div>
            <h1>Series List</h1>
            <div className="card-container">
                <div className="card">
                    <h2>Add New Series</h2>
                    <select value={newSport} onChange={(e) => setNewSport(e.target.value)}>
                        <option value="">Select Sport</option>
                        <option value="foot">Foot</option>
                        <option value="tennis">Tennis</option>
                    </select>
                    {newSport && (
                        <>
                            {newSport === 'foot' && (
                                <>
                                    <select value={newSeriesType} onChange={(e) => setNewSeriesType(e.target.value)}>
                                        <option value="">Select Type</option>
                                        <option value="équipe">Équipe</option>
                                        <option value="autre">Autre</option>
                                    </select>
                                    {newSeriesType === 'équipe' && (
                                        <input
                                            type="text"
                                            value={teamName}
                                            onChange={(e) => setTeamName(e.target.value)}
                                            placeholder="Team Name"
                                        />
                                    )}
                                    {newSeriesType === 'autre' && (
                                        <>
                                            <select value={otherType} onChange={(e) => setOtherType(e.target.value)}>
                                                <option value="">Select Sub-Type</option>
                                                <option value="nombre de tirs">Nombre de tirs</option>
                                                <option value="nombre de tirs cadrés">Nombre de tirs cadrés</option>
                                            </select>
                                            {otherType && (
                                                <select value={subType} onChange={(e) => setSubType(e.target.value)}>
                                                    <option value="">Select Detail</option>
                                                    <option value="équipe">Équipe</option>
                                                    <option value="match">Match</option>
                                                </select>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {newSport === 'tennis' && (
                                <>
                                    <select value={otherType} onChange={(e) => setOtherType(e.target.value)}>
                                        <option value="">Select Type</option>
                                        <option value="nombre de sets">Nombre de sets</option>
                                        <option value="nombre de jeux">Nombre de jeux</option>
                                        <option value="nombre d'aces">Nombre d'aces</option>
                                    </select>
                                </>
                            )}
                        </>
                    )}
                    <button onClick={addNewSeries}>Add New Series</button>
                </div>
                <div className="bankroll-info">
                    <h2>Bankroll</h2>
                    <div className="bankroll-input-container">
                        <label>Amount: </label>
                        <input
                            type="number"
                            value={bankroll}
                            onChange={handleBankrollChange}
                            step="0.01"
                            min="0"
                        />
                    </div>
                    <p>Remaining Series: {calculateRemainingSeries()}</p>
                </div>
            </div>
            <div>
                <select value={filter} onChange={handleFilterChange}>
                    <option value="all">Filter: All</option>
                    <option value="open">Filter: Open</option>
                    <option value="closed">Filter: Closed</option>
                </select>
                <select value={seriesTypeFilter} onChange={handleSeriesTypeFilterChange}>
                    <option value="all">Series Type: All</option>
                    <option value="équipe">Series Type: Équipe</option>
                    <option value="nombre de tirs - match">Series Type: Nombre de tirs - Match</option>
                    <option value="nombre de tirs - équipe">Series Type: Nombre de tirs - Équipe</option>
                    <option value="nombre de tirs cadrés - match">Series Type: Nombre de tirs cadrés - Match</option>
                    <option value="nombre de tirs cadrés - équipe">Series Type: Nombre de tirs cadrés - Équipe</option>
                    <option value="nombre de sets">Series Type: Nombre de sets</option>
                    <option value="nombre de jeux">Series Type: Nombre de jeux</option>
                    <option value="nombre d'aces">Series Type: Nombre d'aces</option>
                </select>
                <select value={sportFilter} onChange={handleSportFilterChange}>
                    <option value="all">Sport: All</option>
                    <option value="foot">Sport: Foot</option>
                    <option value="tennis">Sport: Tennis</option>
                </select>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    placeholder="Search Series"
                    style={{ marginLeft: '10px' }}
                />
                <button
                    onClick={handleZeroEnCoursClick}
                    className={`filter-button ${showZeroEnCours ? 'active' : ''}`}
                >
                    En-cours à 0€
                </button>
                <button
                    onClick={handleNonZeroEnCoursClick}
                    className={`filter-button ${showNonZeroEnCours ? 'active' : ''}`}
                >
                    En-cours # de 0€
                </button>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Sport</th>
                            <th>Series ID</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Last Team Played</th>
                            <th>En-cours pas encore joué</th>
                            <th>Dépenses totales</th>
                            <th>Eligible</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSeries.map(s => (
                            <React.Fragment key={s.series_id}>
                                <tr>
                                    <td>{s.sport}</td>
                                    <td>{s.series_id}</td>
                                    <td>
                                        <Link to={`/series/${s.series_id}`} className="series-link">
                                            {s.name || 'undefined'}
                                        </Link>
                                    </td>
                                    <td>{s.is_closed ? 'Closed' : 'Open'}</td>
                                    <td>{s.type}</td>
                                    <td>{s.lastTeamPlayed}</td>
                                    <td>{s.currentExpenses} €</td>
                                    <td>{s.totalExpenses} €</td>
                                    <td>
                                        <input 
                                            type="checkbox" 
                                            checked={s.eligible} 
                                            onChange={(e) => handleEligibleChange(s.series_id, e.target.checked)} 
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleDeleteSeries(s.series_id)} style={{ marginLeft: '10px' }}>Delete</button>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SeriesManager;
